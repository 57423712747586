import '@/main.scss';

import { captureException, captureMessage, init as initSentry } from '@sentry/browser';

import { appConfig } from './app-config';
import { getToken, initialize } from './auth';

async function main(): Promise<void> {
  const loc = window.location.href + '';
  if (import.meta.env.VITE_USER_NODE_ENV === 'production' && loc.indexOf('http://') === 0) {
    window.location.href = loc.replace('http://', 'https://');
    return;
  }

  if (appConfig.sentryDsn) {
    initSentry({
      dsn: appConfig.sentryDsn,
      environment: appConfig.sentryEnv,

      // provided by the CI/CD pipeline
      ...(appConfig.sentryReleaseVersion ? { release: appConfig.sentryReleaseVersion } : {}),

      beforeSend(event) {
        if (event.message?.includes('ResizeObserver loop limit exceeded')) {
          return null;
        }
        return event;
      },
    });
  }

  const start = async (): Promise<void> => {
    const { mountApp } = await import('./mountApp');
    await mountApp();

    const initialContainer = document.getElementById('initial-container');
    if (initialContainer) {
      initialContainer.remove();
    }
  };

  initialize()
    .then(async (success: boolean) => {
      if (!success) {
        console.error('Failed to initialize Keycloak');
        captureMessage('Keycloak initialization was not successful');
        window.location.reload();
      }

      await getToken();
      await start();
    })
    .catch(async (e: Error): Promise<void> => {
      console.error(e);
      captureException(e);
      const { router } = await import('@/plugins/vue-router/router');
      await router.replace('error');
      await start();
    });
}

main().catch(async (e: Error): Promise<void> => {
  console.error(e);
  captureException(e);
});
