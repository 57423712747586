import { captureMessage } from '@sentry/browser';
import { Service } from '@uai/webapp-config';
import Keycloak from 'keycloak-js';

import { appConfig } from './app-config';
import { sleep } from './utils/misc';

export type UserInfo = { username: string; roles?: string[] };

export const enum RealmRole {
  ADMIN = 'understand.ai-admin',
  DEVELOPER = 'uai-developer',
  INTERNAL = 'uai-internal',
}

let keycloakInstance: Keycloak | undefined = undefined;

const constructKeycloakInstance = async (): Promise<Keycloak> => {
  keycloakInstance = new Keycloak({
    url: appConfig.buildServiceUrl(Service.KEYCLOAK),
    realm: appConfig.realm,
    clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
  });

  keycloakInstance.onAuthRefreshError = (): void => {
    console.error('Failed to refresh Authentication Token');
    captureMessage('Failed to refresh Authentication Token', 'error');
  };
  keycloakInstance.onAuthError = (): void => {
    console.error('Authentication Failure');
    captureMessage('Authentication Failure', 'error');
  };

  return keycloakInstance;
};

export const getKeycloakInstance = (): Keycloak => {
  if (!keycloakInstance) {
    throw Error('Keycloak instance not initialized');
  }
  return keycloakInstance;
};

export const initialize = async (): Promise<boolean> => {
  return (await constructKeycloakInstance()).init({ onLoad: 'login-required', checkLoginIframe: false });
};

async function updateTokenWithRetry(keycloak: Keycloak, maxAttempts = 5, attempt = 1): Promise<boolean> {
  try {
    return keycloak.updateToken(30);
  } catch (e) {
    const msToWait = (2 ** attempt - 1) * 100;
    await sleep(msToWait);

    if (attempt < maxAttempts) {
      console.warn('Failed attempt to refresh Keycloak token will be retried');
      return updateTokenWithRetry(keycloak, maxAttempts, attempt + 1);
    } else {
      throw Error('Could not refresh keycloak token');
    }
  }
}

export async function getUserInfo(): Promise<UserInfo> {
  const keycloak = getKeycloakInstance();

  await updateTokenWithRetry(keycloak);

  if (!keycloak.tokenParsed) {
    throw new Error('No Token found');
  }

  return {
    username: keycloak.tokenParsed.preferred_username,
    roles: keycloak.tokenParsed.realm_access?.roles,
  };
}

export async function getToken(): Promise<string | undefined> {
  const keycloak = getKeycloakInstance();
  await updateTokenWithRetry(keycloak);

  return keycloak.token;
}

export async function logout(): Promise<void> {
  await getKeycloakInstance().logout();
}

export function checkCurrentUserHasRealmRole(realm_role: RealmRole): boolean {
  return getKeycloakInstance().hasRealmRole(realm_role);
}

export function getKeycloakRealm(): string {
  return getKeycloakInstance().realm ?? '';
}
